var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"clue-reassignment-view"},[_c('div',{staticClass:"totalCount"},[_vm._v(" "+_vm._s(_vm.$t("共"))+" "+_vm._s(_vm.totalCount)+" "+_vm._s(_vm.$t("条数据"))+" ")]),_c('List',{ref:"list",attrs:{"fetchApi":_vm.fetchApi},scopedSlots:_vm._u([{key:"default",fn:function({ item, index }){return [_c('div',{staticClass:"item-wrap",on:{"click":function($event){return _vm.onDetail(item.id)}}},[_c('div',{staticStyle:{"overflow":"hidden","flex":"1"}},[_c('div',{staticClass:"user-info mb-8"},[_c('span',{staticClass:"van-ellipsis"},[_vm._v(_vm._s(item.userName))]),_c('span',{staticStyle:{"width":"1px","margin":"0 8px","height":"12px","background-color":"#d9d9d9"}}),_c('span',{staticStyle:{"margin-right":"auto"}},[_vm._v(" "+_vm._s(item.userMobile)+" ")]),(_vm.commonFilterParams.queryType !== 0)?_c('span',{style:({
                flexShrink: 0,
                marginLeft: '8px',
                color: ['1028000'].includes(item.status)
                  ? '#FA5410'
                  : '#029640',
              })},[_vm._v(" "+_vm._s(_vm.getDictNameByCode(1028, item.status))+" ")]):_vm._e()]),_c('div',{staticClass:"mb-8 van-ellipsis"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("改派前门店"))+"：")]),_c('span',[_vm._v(" "+_vm._s(item.orgDealerName)+" ")])]),_c('div',{staticClass:"mb-8 van-ellipsis"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("改派后门店"))+"：")]),_c('span',[_vm._v(" "+_vm._s(item.targetDealerName)+" ")])]),_c('div',{staticClass:"mb-8 van-multi-ellipsis--l2"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("改派原因"))+"：")]),_c('span',[_vm._v(" "+_vm._s(_vm.getReason(item))+" ")])]),_c('div',[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("中央客服"))+"：")]),_c('span',[_vm._v(" "+_vm._s(item.applyUserName || "--")+" ")])])]),(item.status == 1028015)?_c('div',{staticClass:"select-icon",on:{"click":function($event){$event.stopPropagation();return _vm.onSelect(item.id)}}},[_c('van-checkbox',{attrs:{"value":_vm.checkedItems.includes(item.id),"shape":"square"}})],1):_vm._e()])]}}])}),(_vm.commonFilterParams.queryType === 0)?_c('div',{staticClass:"btns-bar"},[_c('van-checkbox',{attrs:{"value":_vm.isAll},on:{"click":_vm.isAllChange}},[_vm._v(" "+_vm._s(_vm.$t("全选"))+" ")]),_c('van-button',{attrs:{"square":"","color":"#EED484"},on:{"click":_vm.handlerAudit}},[_vm._v(" "+_vm._s(_vm.$t("批量审核"))+" "+_vm._s(_vm.checkedItems.length > 0 ? `(${_vm.checkedItems.length})` : "")+" ")])],1):_vm._e(),_c('ReviewFrom',{attrs:{"checkedItems":_vm.checkedItems},on:{"confirm":_vm.handlerConfirm},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }