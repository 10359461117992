<template>
  <div class="clue-reassignment-view">
    <div class="totalCount">
      {{ $t("共") }} {{ totalCount }} {{ $t("条数据") }}
    </div>
    <List ref="list" :fetchApi="fetchApi">
      <template v-slot:default="{ item, index }">
        <div class="item-wrap" @click="onDetail(item.id)">
          <div style="overflow: hidden;flex: 1;">
            <div class="user-info mb-8">
              <span class="van-ellipsis">{{ item.userName }}</span>
              <span
                style="width: 1px;margin: 0 8px;height: 12px;background-color: #d9d9d9;"
              >
              </span>
              <span style="margin-right: auto">
                {{ item.userMobile }}
              </span>
              <span
                v-if="commonFilterParams.queryType !== 0"
                :style="{
                  flexShrink: 0,
                  marginLeft: '8px',
                  color: ['1028000'].includes(item.status)
                    ? '#FA5410'
                    : '#029640',
                }"
              >
                {{ getDictNameByCode(1028, item.status) }}
              </span>
            </div>
            <div class="mb-8 van-ellipsis">
              <span class="label">{{ $t("改派前门店") }}：</span>
              <span>
                {{ item.orgDealerName }}
              </span>
            </div>

            <div class="mb-8 van-ellipsis">
              <span class="label">{{ $t("改派后门店") }}：</span>
              <span>
                {{ item.targetDealerName }}
              </span>
            </div>
            <div class="mb-8 van-multi-ellipsis--l2">
              <span class="label">{{ $t("改派原因") }}：</span>
              <span>
                {{ getReason(item) }}
              </span>
            </div>
            <div>
              <span class="label">{{ $t("中央客服") }}：</span>
              <span>
                {{ item.applyUserName || "--" }}
              </span>
            </div>
          </div>
          <div
            v-if="item.status == 1028015"
            class="select-icon"
            @click.stop="onSelect(item.id)"
          >
            <van-checkbox
              :value="checkedItems.includes(item.id)"
              shape="square"
            />
          </div>
        </div>
      </template>
    </List>

    <div v-if="commonFilterParams.queryType === 0" class="btns-bar">
      <van-checkbox :value="isAll" @click="isAllChange">
        {{ $t("全选") }}
      </van-checkbox>
      <van-button square color="#EED484" @click="handlerAudit">
        {{ $t("批量审核") }}
        {{ checkedItems.length > 0 ? `(${checkedItems.length})` : "" }}
      </van-button>
    </div>

    <ReviewFrom
      v-model="visible"
      :checkedItems="checkedItems"
      @confirm="handlerConfirm"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ReviewFrom from './reviewFrom.vue'
import List from '@/components/baseList/list.vue'
import loading from '@/utils/loading'
import ClueDefeatServices from '@/services/clueDefeatServices'
import { reviewList } from '@/services/approveManage'

import mixins from '../mixins.js'
export default {
  name: 'clueDefeat',
  components: {
    List,
    ReviewFrom,
  },
  mixins: [mixins],
  data() {
    return {
      // 审核选中项集合
      checkedItems: [],
      visible: false,
      totalCount: 0,
      viewCount: 0,
    }
  },
  computed: {
    ...mapGetters(['dictHash', 'userInfo']),
    isAll() {
      return this.viewCount == this.checkedItems.length && !!this.viewCount
    },
  },

  activated() {
    this.onRefresh()
  },
  methods: {
    getReason(item) {
      const { transferReason = '' } = item
      const arr = transferReason.split(',')
      const brr = []
      const array = this.$store.getters.getDictHash(7001)
      for (let index = 0; index < array.length; index++) {
        const { code, name } = array[index]
        if (arr.includes(code)) {
          brr.push(name)
          if (arr.length === brr.length) {
            return brr.join()
          }
        }
      }
      return brr.join()
    },
    onDetail(id) {
      this.$router.push('/clue-reassignment-detail?id=' + id)
    },
    onSelect(str) {
      if (this.checkedItems.includes(str)) {
        const index = this.checkedItems.findIndex((item) => item == str)
        this.checkedItems.splice(index, 1)
      } else {
        this.checkedItems.push(str)
      }
    },
    handlerConfirm() {
      this.onRefresh()
      this.$emit('refresh')
    },
    // 审核
    handlerAudit() {
      if (!this.checkedItems.length) {
        this.$toast(this.$t('请选择待审核线索'))
        return false
      }
      this.visible = true
    },
    // 全选/取消全选
    isAllChange() {
      if (this.isAll) {
        this.checkedItems = []
      } else {
        this.checkedItems = this.$refs.list.list.map(({ id }) => id)
      }
    },
    handlerTo(item) {
      if (item.leadTransferFlag === 1) {
        return this.$toast(this.$t('线索已转出至其他门店，无法查看'))
      }
      this.$router.push({
        path: '/clue-defeat-detail',
        query: {
          id: item.leadId,
          defeatId: item.id,
          applyUserRole: item.applyUserRole,
        },
      })
    },
    onCheckChanged(checked, theClue) {
      if (
        this.checkedItems.length &&
        this.checkedItems[0].applyUserRole !== theClue.applyUserRole
      ) {
        this.$toast(this.$t('请选择相同类型角色申请的线索'))
        return
      }
      const selectList = []
      this.$refs.list.list.forEach((item) => {
        if (item.id === theClue.id) {
          item.checked = checked
        }
        if (item.checked) {
          selectList.push(item)
        }
      })
      this.checkedItems = selectList
    },
    // 获取列表数据
    fetchApi(obj = {}) {
      const {
        queryType,
        reviewStatuses = [],
        reviewTimeStart,
        reviewTimeEnd,
        applyTimeStart,
        applyTimeEnd,
        ...o
      } = this.commonFilterParams
      const params = {
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
        type: 2,
        reviewTime: {
          start: reviewTimeStart || '',
          end: reviewTimeEnd || '',
        },
        applyTime: {
          start: applyTimeStart || '',
          end: applyTimeEnd || '',
        },
        ...o,
        reviewStatus: queryType == 0 ? 1 : 2,
        transferType: 2
      }
      if (reviewStatuses.length) {
        params.statuses = reviewStatuses
      } 
      loading.showLoading()
      return reviewList(params).then((res) => {
        loading.hideLoading()
        this.totalCount = res.page.totalCount
        if (obj.currentPage === 1) {
          this.viewCount = res.dataList.length
          this.checkedItems = []
        } else {
          this.viewCount += res.dataList.length
        }
        return res
      })
    },
    // 刷新
    onRefresh() {
      this.$refs.list.onRefresh()
    },
  },
}
</script>
<style lang="less" scoped>
@checkbox-color: #0d171a;
.btns-bar {
  width: 100%;
  position: absolute;
  justify-content: space-between;
  bottom: 0px;
  display: flex;
  background: #fff;
  padding-left: 16px;
  box-sizing: border-box;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);

  /deep/.van-button {
    color: #0d171a !important;
    font-weight: 500;
    // width: 92px;
  }
}
.clue-reassignment-view {
  .label {
    color: rgba(13, 23, 26, 0.45);
  }

  .mb-8 {
    margin-bottom: 8px;
  }
  .select-icon {
    margin-left: 12px;
    align-self: center;
    font-size: 0;
    cursor: pointer;
    /deep/.van-checkbox__icon {
      font-size: 16px;
      .van-icon {
        border: 2px solid rgba(13, 23, 26, 0.45);
      }
    }
    /deep/.van-checkbox__icon--checked .van-icon {
      background-color: @checkbox-color;
      border-color: @checkbox-color;
      line-height: 1;
    }
  }
  .item-wrap {
    display: flex;
    // flex-direction: column;
    // min-height: 100px;
    // justify-content: space-between;
    background: white;
    padding: 16px 12px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    font-size: 13px;
    overflow: hidden;
    .user-info {
      display: flex;
      align-items: center;
      font-size: 14px;
      .taskName {
        margin-left: auto;
        font-size: 14px;
      }
    }
  }
}
.totalCount {
  color: @black;
  padding: 10px 16px 0 16px;
  font-size: 12px;
}
</style>
